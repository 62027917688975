/* Globales */
.header {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/0003.jpeg");
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}

.header > div:first-child {
    display: flex;
    height: 30%;
}

.header > div:first-child > img {
    margin: 5% 0 0 5%;
}

.header > div:last-child {
    justify-content: center;
    height: 60%;
    display: flex;
}

.header > div:last-child > h2 {
    font-size: 40px;
    flex-wrap: wrap;
    display: flex;
}