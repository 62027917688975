.MisionAndVision {
    display: flex;
    background-color: #f5f5f5;
}

.MisionAndVision > div {
    width: 50%;
}

.MisionAndVision > div > div {
    background-color: white;
    margin: 50px;
    padding: 10px;
    height: 320px;
}

.MisionAndVision > div > div > h4 {
    margin: 20px;
    color: orange;
    font-weight: 600;
    font-size: xx-large;
}

.MisionAndVision > div > div > p {
    margin: 0px;
    font-weight: 400;
}