.AboutUs {
    display: flex;
}

.AboutUs > div {
    width: 50%;
}

.AboutUs > div:first-child > img {
    width: 100%;
}

.AboutUs > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: orange;
}

.AboutUs > div:last-child > h2 {
    font-size: 40px;
    text-align: start;
    margin: 30px;
}

.AboutUs > div:last-child > p {
    text-align: start;
    font-size: 20px;
    font-weight: 400;
    margin: 0px 30px;
}

.AboutUs > div:last-child > div {
    margin-top: 70px;
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.AboutUs > div:last-child > div > button {
    border: 0px solid;
    padding: 10px 15px;
    border-radius: 18px;
    font-weight: 600;
    font-size: 17px;
}