/*Globales*/
.contactanos {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/0021.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
}

/*Titulo*/
.contactanos > div:first-child {
    display: flex;
    width: 100%;
}

.contactanos > div:first-child > h4 {
    font-size: 35px;
    color: white;
    text-align: start;
    padding-left: 10%;
}

/*Formulario*/
.contactanos > div:last-child > form{
    display: flex;
    width: 100%;
}

.contactanos > div:last-child > div > form {
    width: 50%;
}

.contactanos > div:last-child > div:last-child  {
    display: flex;
    justify-content: end;
}

.contactanos > div:last-child > div:last-child > form > div > input {
    border-radius: 16px;
    border: 0px solid;
    padding: 15px;
    width: 90%;
    margin: 0px 0px 15px 0px;
}

.contactanos > div:last-child > div:last-child > form > div:first-child {
    display: flex;
    justify-content: space-evenly;
}

.contactanos > div:last-child > div:last-child > form > div:first-child > input {
    width: 40%;
}

.contactanos > div:last-child > div:last-child > form > div:last-child {
    display: flex;
    padding: 15px;
    justify-content: flex-end;
}

.contactanos > div:last-child > div:last-child > form > div:last-child > button {
    width: 30%;
    padding: 15px;
    border-radius: 16px;
    border: 0px solid;
    background-color: orange;
    color: white;
    font-weight: bolder;
}