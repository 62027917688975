.OurServices {
    display: flex;
    flex-direction: row;
}

.OurServices > div {
    width: 40%;
}

.OurServices > div:first-child {
    display: flex;
    flex-direction: column;
    margin: 5%;
    text-align: start;
    padding: 0px 0px 0px 45px;
    justify-content: center;
}

.OurServices > div:first-child > h2 {
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.OurServices > div:last-child {
    background-color: orange;
    color: white;
    margin: 5%;
}

.OurServices > div:last-child > p {
    text-align: end;
    margin-right: 30px;
}

.OurServices > div:last-child > p > a {
    text-decoration: none;
    color: white;
    text-align: end;
    font-weight: 600;
}

.OurServices > div:last-child > ul {
    text-decoration: none;
    text-align: justify;
}

.OurServices > div:last-child > ul > li {
    margin-bottom: 10px;
}
