.OurProjects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.OurProjects > h2 {
    color: orange;
    font-size: xx-large;
    margin-bottom: 20px;
}

.carousel {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    position: relative;
    justify-content: center;
}

.carousel-inner {
    display: flex;
    flex-direction: row;
    width: 95%; /* Adjusted to show 3 items */
    transition: transform 0.5s ease-in-out;
}

.carousel-item {
    min-width: calc(100% / 3); /* Adjusted to show 3 items */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-item img {
    width: 80%;
    height: auto;
    max-height: 400px; /* Ajusta este valor según tus necesidades */
    object-fit: cover;
    margin: 40px;
}

.carousel-caption {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    color: black;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 800;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(0 0 0 / 0%);
    color: black;
    border: 3px solid;
    padding: 10px;
    cursor: pointer;
    border-radius: 25px;
}

.carousel-control.prev {
    left: 10px;
    z-index: 1;
}

.carousel-control.next {
    right: 10px;
}
