
/*Globales*/
.footer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.footer > div {
    width: 770px;
}

/*Imagen*/
.footer > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15%;
}

.footer > div:first-child > img {
    width: 300px;
}

/*Informacion*/
.footer > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: justify;
}

.footer > div:nth-child(2) > div > h3:first-child {
    color: orange;
}

.footer > div:nth-child(2) > div > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer > div:nth-child(2) > div:last-child > div > p   {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

.footer > div:nth-child(2) > div > div > div > p   {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}

/*Correo*/
.footer > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15%;
}

.footer > div:last-child > h2 > a {
    text-decoration: none;
    color:black;
}